import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import { history } from "utils";
import { unAuthorizedPost } from "services";
import { CREATE_GUARDIAN_PAYMENT_REQUEST } from "graphql";
import { Box, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack } from "@mui/material";
import { Formik } from "formik";
import * as yup from 'yup';
import { guardarianFiatCurrencies } from "datas/guardarianFiatCurrencies";

const QrHome = () => {
    const { username } = useParams();

    const [loading, setLoading] = useState(false);
    const [fiat, setFiat] = useState("USD");

    useEffect(() => {
        if (username) {
            document.body.style.backgroundColor = "#222831";
        }
    }, []);

    const handleSubmit = async (requestData) => {
        try {
            setLoading(true);

            let apiResponse = await unAuthorizedPost("", {
                query: CREATE_GUARDIAN_PAYMENT_REQUEST,
                variables: requestData,
            });

            if (apiResponse.data.customStatus) {
                let {
                    create_guardarian_pmt_req: { data },
                } = apiResponse.data.data;

                window.open(data?.url)
                setLoading(false)
                return;
            } else {
                toast.error("Failed to create payment");
                setLoading(false)
                // return history.push("/nickyjacob");
            }
        } catch (err) {
            toast.error("Unable to create payment");
            setLoading(false)
            // return history.push("/nickyjacob");
        }
    };

    const handleFiatChange = (event) => {
        setFiat(event.target.value);
    };

    const handleFormSubmit = (values) => {
        values.merchantUsername = username
        values.data = {
            from_currency: fiat,
            // from_network: net,
            from_amount: parseFloat(values.amount),
            to_network: "SOL",
            to_currency: "USDC",
        }
        handleSubmit(values);
    };
    const initialValues = {
        email: "",
        amount: "",
    };
    const checkoutSchema = yup.object().shape({
        email: yup.string().email("Invalid email!").required("Required"),
        amount: yup.number().min(1).required("Required"),

    })

    return (
        <>
            <Container>
                <Box sx={{ minHeight: "100vh", display: "flex", alignItems: "center" }}>
                    <Grid container justifyContent={"center"}>
                        <Grid item lg={5} md={6} sm={8} xs={12}>
                            <Box m={2} p={3} bgcolor={"white"} borderRadius={1}>
                                <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
                                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit, }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Stack spacing={2}>
                                                <Stack>
                                                    <InputLabel>Email</InputLabel>
                                                    <OutlinedInput
                                                        fullWidth
                                                        size="small"
                                                        type="text"
                                                        placeholder="Enter email"
                                                        label="Enter email"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.email}
                                                        name="email"
                                                        error={!!touched.email && !!errors.email}
                                                    />
                                                    {Boolean(touched.email && errors.email) ? (
                                                        <div
                                                            style={{
                                                                display: "block",
                                                                marginLeft: "10px",
                                                                color: "red",
                                                                fontSize: 13,
                                                            }}
                                                        >
                                                            {errors.email}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Stack>
                                                <Stack>
                                                    <InputLabel>Amount</InputLabel>
                                                    <OutlinedInput
                                                        fullWidth
                                                        size="small"
                                                        type="number"
                                                        placeholder="Enter amount"
                                                        label="Enter amount"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.amount}
                                                        name="amount"
                                                        error={!!touched.amount && !!errors.amount}
                                                    />
                                                    {Boolean(touched.amount && errors.amount) ? (
                                                        <div
                                                            style={{
                                                                display: "block",
                                                                marginLeft: "10px",
                                                                color: "red",
                                                                fontSize: 13,
                                                            }}
                                                        >
                                                            {errors.amount}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Stack>
                                                <Stack>
                                                    <InputLabel>Select Fiat Currency</InputLabel>
                                                    <FormControl fullWidth>
                                                        <Select fullWidth size="small" value={fiat} onChange={handleFiatChange}>
                                                            {guardarianFiatCurrencies.map((e, i) => (
                                                                <MenuItem value={e.ticker} key={i}>
                                                                    {e.name} ({e.ticker})
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                                <Button 
                                                    fullWidth 
                                                    variant="contained" 
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                >
                                                    {loading ? <CircularProgress size={20} color={"secondary"}/> :  "Continue"}
                                                </Button>
                                            </Stack>
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
};

export default QrHome;
